import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { billService as service } from '../../../services';

export default function TotalBill(props) {
      const [total, setTotal] = useState(0.0);

      useEffect(() => {
            debugger;
            service.sumtotal(props.filter, (data, error) => {
                  if (data && data.data) {
                        setTotal(data.data);
                  } else {
                        setTotal(0.0);
                  }
            });
      }, [props.filter]);

      return (
            <Grid container>
                  <Grid xs={12} sm={12} style={{ color: 'darkgreen' }}>
                        Total de facturas que cumplen las condiciones de búsquedas: <b style={{ fontSize: '20px' }}>{total.toFixed(2)} €</b>
                  </Grid>
            </Grid>
      );
}
