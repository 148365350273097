import BaseService from './_base.service';

const URL = "/admin/bill";

export const billService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    show,
    generate,
    showPatient,
    generatePatient,
    print,
    allFilterPdf,
    allFilterExcel,
    allFilterWord,
    rectify,
    allFilterPrint,
    sumtotal
    
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}


function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}

function sumtotal(filter,callback) {
    return BaseService.post(URL+"/sumtotal", filter, callback, true)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}


function rectify(id,callback) {
    return BaseService.get(URL + "/rectify/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}


function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}


function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}


function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}

function show(from,to,clinic,billdate,callback) {
    return BaseService.get(URL+"/show/"+from+"/"+to+"/"+clinic+"/"+billdate, callback);
}

function generate(from,to,clinic,billdate,callback) {
    return BaseService.get(URL+"/generate/"+from+"/"+to+"/"+clinic+"/"+billdate, callback);
}

function showPatient(from,to,clinic,patient,billdate,callback) {
    return BaseService.get(URL+"/show/"+from+"/"+to+"/"+clinic+"/"+patient+"/"+billdate, callback);
}

function generatePatient(from,to,clinic,patient,billdate,callback) {
    return BaseService.get(URL+"/generate/"+from+"/"+to+"/"+clinic+"/"+patient+"/"+billdate, callback);
}



function print(id,name,callback) {
    return BaseService.getFile(URL + "/print/"+id,name, callback);
}


function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"facturas.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"facturas.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"facturas.docx", callback,)
}

function allFilterPrint(filter,callback) {
    debugger;
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/print/several/filter", filter,"facturasgeneradas.pdf", callback,)
}
