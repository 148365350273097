import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles, drawerWidth } from '../../layout/styles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Modal, TextField } from '@material-ui/core';
import Form from '../../form/Form';
import { appointmentService as service, userService, clinicService, insuranceService, patientService, treatmentService } from '../../../services';
import { isAdmin, idClinic, clinicName } from '../../utils/Utils';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
const moment = require('moment');

const initFilter = {
      name: null,
      email: null,
      phone: null,
      clinicId: null,
      insuranceId: null,
      userId: null,
      patientId: null,
      start: '',
      end: '',
};

const initElement = {
      clinic: { id: null, name: null },
      insurance: { id: null, name: null },
      patient: { id: null, name: null },
      fisio: { id: null, name: null },
      dateAppointment: '',
      hourAppointment: '',
      isPayament: false,
      isBill: false,
      notes: '',
      canceled: false,
      motiveCanceled: '',
      treatment: [],
};

const style = {
      position: 'absolute' as 'absolute',
      top: '20px',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      width: 2000,
      maxWidth: '90%',
      maxHeight: '80%',
      bgcolor: 'white',
      border: '2px solid #000',
      boxShadow: 24,
      overflowY: 'auto',
      p: 4,
};

export default function Dashboard() {
      const classes = useStyles();
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
      const [events, setEvents] = useState([]);
      const [clinics, setClinics] = useState<any>([]);
      const [filter, setFilter] = useState({ ...initFilter, ...{ clinicId: idClinic() } });
      const [cSelected, setCSelected] = useState<any>({ key: idClinic() > 0 ? idClinic() : -1, name: idClinic() > 0 ? '' : 'Todas' });

      const [open, setOpen] = useState(false);
      const [openNew, setOpenNew] = useState(false);
      const [openEdit, setOpenEdit] = useState(false);
      const [refresh, setRefresh] = useState(0);
      const [evs, setEvs] = useState({});
      const [ap, setAp] = useState({ ...initElement });
      const [apid, setApid] = useState(0);
      const [clicked, setClicked] = useState(false);
      const [idContext, setIdContext] = useState(0);
      const [points, setPoints] = useState({
            x: 0,
            y: 0,
      });

      const handleDrawerOpen = () => {
            setOpen(true);
      };
      const handleDrawerClose = () => {
            setOpen(false);
      };

      const handleMNewOpen = () => {
            setOpenNew(true);
      };
      const handleMNewClose = () => {
            setAp({
                  clinic: { id: null, name: null },
                  insurance: { id: null, name: null },
                  patient: { id: null, name: null },
                  fisio: { id: null, name: null },
                  dateAppointment: '',
                  hourAppointment: '',
                  isPayament: false,
                  isBill: false,
                  notes: '',
                  canceled: false,
                  motiveCanceled: '',
                  treatment: [],
            });
            setOpenNew(false);
            setApid(0);
      };

      const handleMEditOpen = () => {
            setOpenEdit(true);
      };
      const handleMEditClose = () => {
            setAp({
                  clinic: { id: null, name: null },
                  insurance: { id: null, name: null },
                  patient: { id: null, name: null },
                  fisio: { id: null, name: null },
                  dateAppointment: '',
                  hourAppointment: '',
                  isPayament: false,
                  isBill: false,
                  notes: '',
                  canceled: false,
                  motiveCanceled: '',
                  treatment: [],
            });
            setOpenEdit(false);
            setApid(0);
      };

      const handleDateClick = (arg) => {
            // bind with an arrow function
            alert(arg.dateStr);
      };

      const estructureform = [
            {
                  type: 'autocomplete',
                  name: 'patient.id',
                  label: 'Paciente',
                  service: patientService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'clinic.id',
                  label: 'Clinica',
                  service: clinicService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'insurance.id',
                  label: 'Aseguradora',
                  service: insuranceService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'fisio.id',
                  label: 'Fisio',
                  service: userService.combo,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'dateAppointment',
                  label: 'Fecha',
                  inputtype: 'date',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'hourAppointment',
                  label: 'Hora',
                  inputtype: 'time',
                  col: 4,
            },
            {
                  type: 'checkbox',
                  name: 'isBill',
                  label: 'Facturado',
                  col: 4,
            },
            {
                  type: 'checkbox',
                  name: 'isPayament',
                  label: 'Pagado',
                  col: 4,
            },
            {
                  type: 'textarea',
                  name: 'notes',
                  label: 'Notas',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'valoration',
                  label: 'Valoración',
                  col: 12,
            },
            {
                  type: 'checkbox',
                  name: 'notPresent',
                  label: 'No se presento',
                  col: 4,
            },
            {
                  type: 'checkbox',
                  name: 'canceled',
                  label: 'Cancelada',
                  col: 4,
            },
            {
                  type: 'textarea',
                  name: 'motiveCanceled',
                  label: 'Motivo de cancelacion',
                  col: 12,
            },
            {
                  type: 'autocomplete',
                  name: 'treatment',
                  multiple: true,
                  label: 'Tratamiento',
                  service: treatmentService.combo,
                  col: 12,
            },
      ];

      const searchWeek = () => {
            service.eventsWeek(filter, (data, error) => {
                  if (data) {
                        setEvents(data.data);
                  }
            });
      };

      const search = () => {
            service.events(filter, (data, error) => {
                  if (data) {
                        setEvents(data.data);
                  }
            });
      };

      const searchAp = () => {
            service.one(apid, (data, error) => {
                  if (data) {
                        var element = { ...data.data };
                        if (element['insurance'] == null) {
                              element['insurance'] = { id: null, name: null };
                        }
                        if (element['patient'] == null) {
                              element['patient'] = { id: null, name: null };
                        }
                        if (element['clinic'] == null) {
                              element['clinic'] = { id: null, name: null };
                        }
                        if (element['fisio'] == null) {
                              element['fisio'] = { id: null, name: null };
                        }
                        if (element['treatment'] == null) {
                              element['treatment'] = [];
                        }
                        setAp(element);
                        handleMEditOpen();
                  }
            });
      };

      const constChangeRangeDate = (events) => {
            debugger;
            var havesearch = false;
            var update = {
                  start: '',
                  end: '',
            };
            if (events.start) {
                  update['start'] = moment(events.start).format('YYYY-MM-DD') + 'T00:00:00Z';
            }
            if (events.end) {
                  update['end'] = moment(events.end).format('YYYY-MM-DD') + 'T23:59:00Z';
            }

            if (update['start'] != '') {
                  setFilter((currentFilter) => ({ ...currentFilter, ...update }));
            }
      };

      const handleNewAppointment = (selectInfo) => {
            console.log(selectInfo);
            var date = selectInfo.startStr;

            var update = {
                  dateAppointment: moment(date).format('YYYY-MM-DD') + 'T00:00:00Z',
                  hourAppointment: moment(date).format('HH:mm'),
            };

            var updateEl = { ...ap, ...update };
            setAp(updateEl);

            setOpenNew(true);
      };

      const handleEditAppointment = (selectInfo) => {
            console.log(selectInfo);

            setApid(+selectInfo.event.id);
      };

      const saveForm = (object) => {
            if (apid && apid > 0) {
                  update(object, false);
            } else {
                  create(object, false);
            }
      };

      const create = (object, cleanSerie) => {
            service.create(object, (data, error) => {
                  if (data) {
                        setRefresh(data.data.id);
                        handleMNewClose();
                  }
            });
      };

      const update = (object, cleanSerie) => {
            service.edit(object['id'], object, (data, error) => {
                  if (data) {
                        setRefresh(data.data.id);
                        handleMEditClose();
                  }
            });
      };

      const paintSpecialChar = (arg) => {
            return (
                  <div style={{ padding: '4px' }}>
                        {arg.event._def.extendedProps['PAY'] && arg.event._def.extendedProps['PAY'] != '' && (
                              <span>
                                    {' '}
                                    <b
                                          style={{
                                                color: 'green',
                                                background: 'white',
                                                display: 'inline-flex',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '9px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                justifyItems: 'center',
                                                borderRadius: '10px',
                                                marginRight: '5px',
                                          }}
                                    >
                                          P
                                    </b>
                              </span>
                        )}
                        <span>{arg.event._def.title}</span>
                  </div>
            );
      };

      const eventDidMount = (evt) => {
            evt.el.addEventListener('contextmenu', (jsEvent) => {
                  jsEvent.preventDefault();
                  console.log('contextMenu', evt.event.id);
                  setIdContext(evt.event.id);
                  setClicked(true);
                  setPoints({
                        x: jsEvent.pageX,
                        y: jsEvent.pageY,
                  });
            });
      };

      const addAction = (type) => {
            setClicked(false);
            service.action(idContext, type, (data, error) => {
                  if (data && data.data) {
                        setRefresh(idContext);
                  }
            });
      };

      useEffect(() => {
            clinicService.combo((data, error) => {
                  if (data && data.data) {
                        var aux = [{ key: -1, name: 'Todas' }];
                        aux = aux.concat(data.data);
                        setClinics(aux);
                  }
            });
            const handleClick = () => setClicked(false);
            window.addEventListener('click', handleClick);
            return () => {
                  window.removeEventListener('click', handleClick);
            };
      }, []);

      useEffect(() => {
            if (refresh > 0) {
                  search();
            }
      }, [refresh]);

      useEffect(() => {
            if (apid > 0) {
                  searchAp();
            }
      }, [apid]);

      useEffect(() => {
            debugger;
            if (filter.start != null && filter.start != '') {
                  search();
            } else {
                  //searchWeek();
            }
      }, [filter]);

      return (
            <div className={classes.root}>
                  <CssBaseline />
                  <Header title="Agenda" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                              <Grid container spacing={3} style={{ paddingTop: '2em' }}>
                                    {isAdmin() && (
                                          <Grid xs={12}>
                                                <h3 style={{ textAlign: 'center' }}>
                                                      Agenda de la clínica
                                                      <Autocomplete
                                                            id={'clinic'}
                                                            options={clinics}
                                                            getOptionLabel={(option) => {
                                                                  return option.name;
                                                            }}
                                                            style={{ width: '300px', display: 'inline-block', marginLeft: '20px' }}
                                                            value={cSelected}
                                                            onChange={(event, value) => {
                                                                  if (value?.key > 0) {
                                                                        setFilter((currentFilter) => ({ ...currentFilter, ['clinicId']: value?.key }));
                                                                  }

                                                                  setCSelected(value);
                                                            }}
                                                            renderInput={(params) => <TextField {...params} label={''} />}
                                                      />{' '}
                                                </h3>
                                          </Grid>
                                    )}
                                    <Grid item sm={6} xs={12} style={{ textAlign: 'left', marginBottom: '2em' }}>
                                          <span style={{ background: '#6c89e5', display: 'inline-block', marginRight: '5px', width: '20px', height: '20px', borderRadius: '5px' }}> </span>
                                          <span style={{ marginRight: '10px' }}>Cancelada</span>
                                          <span style={{ display: 'inline-block', marginRight: '5px', width: '20px', height: '20px', borderRadius: '5px', background: '#ff0000' }}> </span>
                                          <span style={{ marginRight: '10px' }}>No se prensento</span>
                                          <span style={{ display: 'inline-block', marginRight: '5px', width: '20px', height: '20px', borderRadius: '5px', background: '#bfd1d1' }}> </span>
                                          <span style={{ marginRight: '10px' }}>Recuperación</span>
                                          <span style={{ display: 'inline-block', marginRight: '5px', width: '20px', height: '20px', borderRadius: '5px', background: '#83c63b' }}> </span>
                                          <span style={{ marginRight: '10px' }}>Activa</span>
                                    </Grid>
                                    <Grid item sm={6} xs={12} style={{ textAlign: 'right', marginBottom: '2em' }}>
                                          <Button component={Link} to={'/appointment/new'} variant="contained" color="secondary" style={{ marginLeft: '30px' }}>
                                                {' '}
                                                Nueva cita{' '}
                                          </Button>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ textAlign: 'left', borderBottom: '1px solid #ccc', marginBottom: '2em' }}>
                                          <FullCalendar
                                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                headerToolbar={{
                                                      left: 'prev,next today',
                                                      center: 'title',
                                                      right: window.innerWidth < 760 ? '' : 'timeGridWeek,timeGridDay,dayGridMonth',
                                                }}
                                                titleFormat={{ year: 'numeric', month: '2-digit', day: '2-digit' }}
                                                buttonText={{
                                                      today: 'Hoy',
                                                      month: 'Mes',
                                                      week: 'Semana',
                                                      day: 'Día',
                                                      list: 'List',
                                                      allDay: '',
                                                }}
                                                timeZone="local"
                                                slotMinTime={'09:00:00'}
                                                slotMaxTime={'21:00:00'}
                                                slotMinWidth={15}
                                                allDaySlot={false}
                                                contentHeight={800}
                                                initialView={window.innerWidth < 760 ? 'timeGridDay' : 'timeGridWeek'}
                                                firstDay={1}
                                                scrollTime={8}
                                                editable={true}
                                                selectable={true}
                                                selectMirror={true}
                                                dayMaxEvents={true}
                                                weekends={false}
                                                locale="es"
                                                events={events}
                                                eventClick={handleEditAppointment}
                                                select={handleNewAppointment}
                                                datesSet={constChangeRangeDate}
                                                eventContent={paintSpecialChar}
                                                eventDidMount={eventDidMount}
                                          />
                                    </Grid>
                              </Grid>
                              <Box pt={4}>
                                    <Copyright />
                              </Box>
                        </Container>
                        <Modal open={openNew} onClose={handleMNewClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                              <Box sx={style}>
                                    <Grid container>
                                          <Grid xs={12}>
                                                <h2>Nueva cita</h2>
                                          </Grid>
                                          <Grid xs={12}>
                                                <Form
                                                      objectform={ap}
                                                      fields={estructureform}
                                                      searchFilter={saveForm}
                                                      cleanFilter={() => handleMNewClose()}
                                                      labelsave={'Guardar'}
                                                      labelcancel={'Cancelar'}
                                                      haveMoreActions={false}
                                                      moreActions={null}
                                                      forcesave={false}
                                                />
                                          </Grid>
                                    </Grid>
                              </Box>
                        </Modal>
                        <Modal open={openEdit} onClose={handleMEditClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                              <Box sx={style}>
                                    <Grid container>
                                          <Grid xs={12}>
                                                <h2>Editar/ver cita</h2>
                                          </Grid>
                                          <Grid xs={12}>
                                                <Form
                                                      objectform={ap}
                                                      fields={estructureform}
                                                      searchFilter={saveForm}
                                                      cleanFilter={() => handleMEditClose()}
                                                      labelsave={'Guardar'}
                                                      labelcancel={'Cancelar'}
                                                      haveMoreActions={false}
                                                      moreActions={null}
                                                      forcesave={false}
                                                />
                                          </Grid>
                                    </Grid>
                              </Box>
                        </Modal>

                        {clicked && (
                              <div style={{ position: 'fixed', width: '300px', top: points.y, left: points.x, zIndex: 1000, background: '#fcfcfc', padding: '10px', border: '1px solid #ccc', borderRadius: '6px' }}>
                                    <button style={{ display: 'block', width: '100%', border: 'none', background: '#fcfcfc', padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }} onClick={() => addAction(1)}>
                                          Cancelada
                                    </button>
                                    <button style={{ display: 'block', width: '100%', border: 'none', background: '#fcfcfc', padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }} onClick={() => addAction(2)}>
                                          No se presento
                                    </button>
                                    <button style={{ display: 'block', width: '100%', border: 'none', background: '#fcfcfc', padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }} onClick={() => addAction(3)}>
                                          Pagada
                                    </button>
                                    <button style={{ display: 'block', width: '100%', border: 'none', background: '#fcfcfc', padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }} onClick={() => addAction(4)}>
                                          Cita de recuperación
                                    </button>
                                    <button style={{ display: 'block', width: '100%', border: 'none', background: '#fcfcfc', padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }} onClick={() => addAction(5)}>
                                          Borrar cita
                                    </button>
                              </div>
                        )}
                  </main>
            </div>
      );
}
